// .page-a4 {
//   width: 210mm;
//   min-height: 297mm;
//   padding: 5mm;
//   margin: 10mm auto;
//   border: 1px #d3d3d3 solid;
//   border-radius: 5px;
//   background: white;
//   box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
// }

// @page {
//   size: A4;
//   margin: 0;
// }
// @media print {
//   html,
//   body {
//     width: 210mm;
//     height: 297mm;
//   }
//   .page-a4 {
//     margin: 0;
//     border: initial;
//     border-radius: initial;
//     width: initial;
//     min-height: initial;
//     box-shadow: initial;
//     background: initial;
//     page-break-after: always;
//   }
//   .button-print {
//     display: none;
//   }
// }


@page {
  margin: 10mm;
  size: auto;
}

.page-header,
.page-header-space {
  height: 320px;
}

.page-footer,
.page-footer-space {
  height: 50px;
}

.underline {
  margin-top: 15px;
  border-top: 1px solid rgb(220, 220, 220);
  margin-bottom: 15px;
}

.underlineSignature {
  border-top: 0.8px solid rgb(220, 220, 220);
  text-align: center;
}

.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.page-header {
  position: fixed;
  top: 0mm;
  width: 100%;
}

.footer-signature {
  bottom: 0;
  width: 100%;
}

.page {
  page-break-after: always;
}

table.report-container {
  page-break-after: always;
  break-after: page;
}

@media print {

  thead {
    display: table-header-group;
  }

  .page-header {
    height: 350px;
  }

  body {
    height: auto;
    position: relative;

    .footer-signature {
      position: absolute;
      // top: 100%;
      // bottom: auto;
      // transform: translateY(-100%);
      // margin-top: 50px;

    }
  }

  tbody .report-content {
    margin: 0;
    width: 100%;
    display: block;
    flex-direction: column;
  }
}
@media screen {
  .page-header,
  .page-header-space {
    display: none;
  }
}

.table-header-color{
  background-color: #04AFE3 !important;
  text-align: center;
}
