#invoices-container {
  .invoices-text-detail {
    margin-left: 5px;
  }
  .invoices-text-price {
    font-size: 1.7rem;
    margin-left: 5px;
  }
  .invoices-text-discount-detail {
    margin-left: 15px;
  }
  .invoices-text-discount-price {
    font-size: 1.7rem;
    margin-left: 15px;
  }
  .detail-before-table {
    border-top: 0px solid $main-color-2;
    border-bottom: 0px solid $main-color-2;
  }

  .invoices-totalprice {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
  }
  .invoices-price-paid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 25px 30px 25px;
    margin-bottom: 20px;
    border-radius: 13px;
    background: #0cb1e1;
    color: #fff;
    font-size: 1.3rem;
  }
  .invoices-price-detail {
    display: flex;
    justify-content: space-between;
    font-size: 1.3rem;
    .invoices-discount-coupon {
      margin-right: 20px;
      text-align: left;
      padding: 30px 15px 30px 15px;
      background: #f0a12b;
      border-radius: 13px;
      color: #fff;
      width: 100%;
    }
    .invoices-discount-other {
      text-align: left;
      padding: 30px 15px 30px 15px;
      background: #f22f6a;
      border-radius: 13px;
      color: #fff;
      width: 100%;
    }
  }
}
