$main-color-1: #04afe3;
$main-color-2: #ffcc00;

.bouncing-text {
    position: relative;
    display: flex;
    font-size: 60px;
    // font-family: arial black;  
  }

  .c, .o-1, .m-1, .i, .n-1,.g,.s,.o-2 , .o-3 ,.n-2 ,.dot-1,.dot-2,.dot-3{
    color: $main-color-1;
    text-shadow: 0 3px $main-color-2, 0 5px $main-color-2, 0 7px $main-color-2;
    // color: #4cc9f0;
    // text-shadow: 0 3px #4361ee, 0 5px #4361ee, 0 7px #4361ee;
  }

  @keyframes bounce {
    0%   { transform: scale(1,1) translateY(0); }
    10%  { transform: scale(1.1,.9) translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-55px);}
    50%  { transform: scale(1.05,.95) translateY(0); }
    58%  { transform: scale(1,1) translateY(-7px); }
    65%  { transform: scale(1,1) translateY(0);}
    100% { transform: scale(1,1) translateY(0);}
}

.c {
animation: bounce 2s ease infinite;
}

.o-1 {
animation: bounce 2s ease infinite .1s;
}

.m-1 {
animation: bounce 2s ease infinite .2s;
}

.m-2 {
animation: bounce 2s ease infinite .3s;
}

.i {
animation: bounce 2s ease infinite .4s;
}

.n-1 {
animation: bounce 2s ease infinite .5s;
}
.g {
animation: bounce 2s ease infinite .6s;
}

.s {
animation: bounce 2s ease infinite .7s;
}
.o-2 {
animation: bounce 2s ease infinite .8s;
}
.o-3{
animation: bounce 2s ease infinite .9s;
}
.n-2 {
animation: bounce 2s ease infinite 1s;
}
.dot-1 {
animation: bounce 2s ease infinite 1.1s;
}
.dot-2 {
animation: bounce 2s ease infinite 1.2s;
}
.dot-3 {
animation: bounce 2s ease infinite 1.3s;
}


// .b {
// animation: bounce 1s ease infinite;
// }

// .o {
// animation: bounce 1s ease infinite .1s;
// }

// .u {
// animation: bounce 1s ease infinite .2s;
// }

// .n {
// animation: bounce 1s ease infinite .3s;
// }

// .c {
// animation: bounce 1s ease infinite .4s;
// }

// .e {
// animation: bounce 1s ease infinite .5s;
// }